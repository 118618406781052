/*------------------------------------------------------------------
# [Forms]
------------------------------------------------------------------*/

form {

  fieldset {
    padding: 0;
    border-width: 0;
    margin-bottom: @base-line-height * 2;
  }

  legend {
    display: block;
    width: 100%;
    margin-bottom: @base-line-height;
    padding: 0;
    color: @text-color;
    border: 0;

    white-space: normal;
    h1 {
      text-align: left;
    }
    small {
      .font-size(14);
      color: @grey-light;
    }
  }
}

input[type=search] {
  -webkit-appearance: none;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
  display: none;
}

label,
input,
button,
select,
textarea {
  .font-size(14);
  vertical-align: baseline;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="url"],
input[type="search"],
input[type="password"],
textarea {
  .box-sizing();
  .font-size(16);
  border: @input-border-width @input-border-style @input-border;
  height: 50px;
  color: @input-text-color;
  background-color: @input-background;
  padding: 0 20px;
  width: 100%;
}

.has-error {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  input[type="url"],
  input[type="search"],
  input[type="password"],
  textarea {
    border-color: @red;
  }
  .help-block {
    color: @red;
  }
}

textarea {
  height: 100px;
  padding: 10px 20px;
}

input[type="submit"] {
  .box-sizing();
  height: 40px;
}

input:focus,
textarea:focus,
select:focus {
  border-color: @input-focus;
  outline: 0;
}

.form-row {
  position: relative;
  .box-sizing;
  padding: 10px 0;
  &.has-error {
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="url"],
    input[type="search"],
    input[type="password"],
    textarea {
      border-color: @red;
    }

  }
  @media @bp_768 {
    padding-left: 170px;
    padding-top: 0px;
    padding-bottom: 10px;
  }

  @media @bp_1400 {
    padding-bottom: 10px;
  }

  small, .help-block {
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
    line-height: 16px;
  }

  > label {
    text-transform: uppercase;

    @media @bp_768 {
      position: absolute;
      left: 0px;
      top: 5px;
      width: 150px;
    }
  }

  .form-row {

    @media @bp_768 {
      padding: 5px 0;

      &.field-bookingform-dob_month {
        padding: 0;
      }
    }

    label {
      position: relative;
      display: inline-block;
      top: 2px;
      vertical-align: top;
      text-transform: none;
      line-height: 16px;
      width: auto;
    }
  }
}

.date-selector {
  background: url('../images/icon-calendar-black.svg') no-repeat right 10px center;
  background-size: 30px;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
  &.checkbox {
    margin-left: 170px;
    top: 6px;
    display: unset;
    width: auto;
    position: absolute;
    float: left;
  }
}

.field-bookingform-has_agreed_sub {
  position: relative;
  display: block;

  label {
    margin-left: 170px;
    margin-bottom: 10px;
  }
}

input[type="radio"] + label,
input[type="checkbox"] + label {
  cursor: pointer;
  padding-left: 20px;
  position: relative;
}

/*
input[type="radio"] + label:before,
input[type="checkbox"] + label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid @gold;
  border-radius: 3px;
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: middle;
  background-color: @white;
  cursor: pointer;
}

input[type="checkbox"] + label:after {
  content: "\f00c";
  font-family: FontAwesome, serif;
  position: absolute;
  top: .3em;
  left: .2em;
  font-size: 1em;
  line-height: 0.8;
  color: @gold;
  transition: all .2s;
}

input[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
}

input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
*/
input[type="radio"] + label:before {
  .rounded-corners(100%);
}

input[type="radio"]:checked + label:before,
input[type="checkbox"]:checked + label:before {
  cursor: pointer;
}

select {
  .font-size(16);
  .box-sizing();
  text-align: left;
  //display: block;
  width: 100%;
  //padding: 0 46px 0 20px;
  //line-height: 40px;
  border: @input-border-width @input-border-style @input-border;
  height: 50px;
  color: @input-text-color;
  //-webkit-appearance: none;
  //-moz-appearance: none;
  //appearance: none;
  background: @input-background url('../images/icon-arrow-down.svg') no-repeat right 8px center;
  background-size: 30px;
  cursor: pointer;

  /*option {
      background-color: @white;
      color: @text-color;
      outline: 0;
      border: 1px solid @gold;
      cursor: pointer;

      &:hover {
          background-color: @white;
      }
  }*/
}

select:invalid {
  color: @text-color;
}

select::-ms-expand {
  display: none;
}

select:focus {
  outline: none;
}

.form-msg {
  .rounded-corners(5px);
  border: 2px solid @grey;
  text-align: left;
  padding: 10px 60px 10px 20px;
  text-transform: uppercase;
  background-position: right 20px center;
  background-repeat: no-repeat;
  background-size: 30px;

  &.msg-success {
    border-color: @green;
    background-color: @green;
    background-image: url('../images/icon-success.svg');
  }

  &.msg-error {
    border-color: @red;
    background-image: url('../images/icon-error.svg');
  }
}

input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #0069B5;
  border-radius: 0px;
  border: 0px solid #000101;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: #585858;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #0069B5;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #0069B5;
  border-radius: 0px;
  border: 0px solid #000101;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: #585858;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #0069B5;
  border: 0px solid #000101;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
  background: #0069B5;
  border: 0px solid #000101;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 0px solid #000000;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: #585858;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #0069B5;
}

input[type=range]:focus::-ms-fill-upper {
  background: #0069B5;
}

.gold .select2-container--default .select2-selection--single {
  background-color: @gold !important;
  color: @white;
}

.gold .select2-container.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: @white !important;
}

.field-bookingform-dob_month {
  padding: 0;
  width: 150px !important;

  .select2.select2-container.select2-container--default{
    width: 150px !important;

    .select2-selection {
      width: 150px;
      background-color: @input-background;
      border: 0;

      .select2-selection__rendered {
        color: #585858;
      }
    }
  }
}
/*
#ui-datepicker-div {

  border-radius: 0;

  table {
    border: 0;
  }

  &.ui-datepicker {
    padding: 0;

    td {
      padding: 0;
      background-color: @white;
      color: @black;
      transition: 0.4s;

      &.ui-datepicker-today {
        color: @black;

        a {
          color: black;
          font-weight: 500;
        }
      }

      &.ui-datepicker-current-day {
        background-color: @gold;
        a {
          color: @white;
        }
      }

      a {
        font-family: 'Roboto', serif;
        text-align: center;
        margin: 3px 0;
      }
    }

    td:hover {
      background-color: @gold;
      transition: 0.3s;

      a {
        color: @white;
        transition: 0.3s;
      }
    }

    tr:nth-child(odd) {
      background: @white;
    }

    .ui-widget-content .ui-icon {
      background: 0;
    }
    .ui-widget-header .ui-icon {
      background: 0;
    }

    .ui-state-default, .ui-widget-content {
      border: 0;
      background: 0;
    }

    .ui-state-hover, .ui-datepicker-prev-hover, .ui-datepicker-prev-next {
      border: 0;
      color: @white;
      font-weight: unset;
      right: 0;
      left: 0;
    }

    .ui-datepicker-prev {
      background: 0;
    }

    .ui-datepicker-prev:before {
      content: "\F104";
      font-family: FontAwesome, serif;
      left: 10px;
      top: 8px;
      position: absolute;
      color: @white;
    }

    .ui-datepicker-next {
      background: 0;
    }

    .ui-datepicker-next:before {
      content: "\F105";
      font-family: FontAwesome, serif;
      left: 10px;
      top: 8px;
      position: absolute;
      color: @white;
    }
  }

  .ui-datepicker-header {
    border: 0;
    background: @gold 0 0;
    border-radius: 0;

    .ui-datepicker-title {
      color: @white;
      font-family: 'Roboto', serif;
      font-weight: 500;
      padding: 5px 0;
    }
  }
}
*/