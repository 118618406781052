/*------------------------------------------------------------------
# [Banners]
------------------------------------------------------------------*/

.banner {
	.clearfix();
	position: relative;

	&.banner-top {
		clear: both;
		background-color: @black;
		color: @white;
		.font-size(10);
		text-align: center;

		@media @bp_768 {
			.font-size(12);
		}

		@media @bp_1024 {
			display: none;
		}

		a {
			color: @white;
			display: block;
			padding: 20px 50px;
		}

		br {

			@media @bp_768 {
				display: none;
			}
		}
	}

	.close {
		position: absolute;
		top: 50%;
		margin-top: -10px;
		width: 20px;
		height: 20px;
		right: 20px;
		background-image: url('../images/icon-close-banner.svg');
		background-size: 20px;
		background-repeat: no-repeat;
		background-position: center center;
		cursor: pointer;
	}
}