/*------------------------------------------------------------------
# [Print]
------------------------------------------------------------------*/

@media print {

	* {
		background: transparent !important;
		color: black !important;
		box-shadow: none !important;
		text-shadow: none !important;
		filter: none !important;
		-ms-filter: none !important;
	}

	@page {
		margin: 0.5cm;
	}

	h2, h3 {
		orphans: 3;
		widows: 3;
		page-break-after: avoid;
	}

	p {
		orphans: 3;
		widows: 3;
	}

	pre, blockquote {
		border: 1px solid @grey;
		page-break-inside: avoid;
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	a, a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	a[href^="javascript:"]:after,
	a[href^="#"]:after {
		content: "";
	}

	img {
		max-width: 100% !important;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	tr {
		page-break-inside: avoid;
	}

}