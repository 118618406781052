/*------------------------------------------------------------------
# [Typography]
------------------------------------------------------------------*/

//GOOGLE FONTS
@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i&subset=latin-ext);

h1, h2, h3, h4, h5, h6 {
	font-family: Georgia;
	font-weight: normal;
	color: @gold;
}

h1 {
	font-style: italic;
	.font-size(30);
	line-height: 1.18;
	letter-spacing: 0.9px;
	margin: 0 0 29px 0;
	text-align: center;
	
	@media @bp_768 {
		.font-size(38);
	}
}

h2 {
	.font-size(24);
	font-style: italic;
	line-height: 1.38;
	letter-spacing: 1.2px;
	margin: 0 0 10px 0;
	
	@media @bp_768 {
		.font-size(38);
		line-height: 1.18;
		letter-spacing: 0.9px;
		margin: 0 0 27px 0;
	}
}

h3 {
	.font-size(18);
	line-height: 1.3;
	letter-spacing: 0.7px;
	margin: 0 0 14px 0;
}

h4 {
	.font-size(16);
	line-height: 1.5;
	margin: 0 0 14px 0;
}

h5 {
	color: @grey;
	.font-size(24);
	line-height: 1.25;
	letter-spacing: 0.6px;
	margin: 0 0 14px 60px;
}

h6 {
	color: @grey;
	font-family: 'Roboto', sans-serif;
	.font-size(16);
	font-weight: 300;
	line-height: 1.81;
	letter-spacing: 0.9px;
	margin: 0 0 5px 60px;
}

h7 {
	color: @grey;
	font-family: 'Roboto', sans-serif;
	.font-size(15);
	font-weight: 300;
	line-height: 1.47;
	letter-spacing: 0.6px;
	margin: 0 0 5px 60px;
}

a {
	color: @gold;
	text-decoration: none;
	.box-sizing();
	border-bottom: 1px solid transparent;

	&:hover,
	&:active {
		outline: 0;
	}

	img {
		border: none;
	}
}

p {
	color: @grey;
	font-size: 12px;
	font-weight: 300;
	line-height: 1.42;
	letter-spacing: 0.9px;
	margin: 1em 0;

	@media @bp_768 {
		font-size: 16px;
		line-height: 1.81;
		//margin: 0 auto 45px auto;
	}
}

blockquote {
	font-family: Georgia;
	font-size: 22px;
	font-weight: normal;
	font-style: italic;
	line-height: 1.5;
	color: @gold;
	text-align: center;
}

b, strong { font-weight: bold; }
dfn { font-style: italic; }

pre,
code,
kbd,
samp {
	//font-family: @codefont;
	.font-size(14);
	line-height: @base-line-height;
}

pre {
	white-space: pre;
	white-space: pre-wrap;
	word-wrap: break-word;
}

sub,
sup {
	position: relative;
	.font-size(10);
	line-height: 0;
	vertical-align: @base-line-height;
}

sup {
	top: -.5em;
}

sub {
	bottom: -.25em;
}


::-moz-selection {
	background-color: lighten(@base-color, 65%);
	color: @base-color;
	text-shadow: none;
}

::selection {
	background-color: lighten(@base-color, 65%);
	color: @base-color;
	text-shadow: none;
}
