.g-container {
  position: relative;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}

.g-section {
  background-color: @white;
  padding: 5rem 0 7rem;
  text-align: center;
}

.row {
  max-width: 940px;
  margin: 0 auto;
  position: relative;
  height: 100%;
}

.column {
  width: 100%;
  float: left;
  box-sizing: border-box;
}

.listw {
  li {
    display: inline-block;
    position: relative;
    text-align: left;
    font-size: 1.2em;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 1rem;
    padding-left: 20px;
    vertical-align: top;
  }
  li:before {
    display: block;
    position: absolute;
    left: 0;
    content: "\002022";
    content: "✔";
    padding-right: 10px;
    padding-right: 0.625rem;
  }
}

.gold {
  color: @gold;
}

@media @min400 {
  .g-container {
    width: 85%;
    padding: 0;
  }
}

@media @min550 {
  .g-container {
    width: 80%;
  }

  .g-section {
    padding: 3rem 0 5rem;
  }

  .column {
    margin-left: 4%;
  }

  .column:first-child {
    margin-left: 0;
  }

  .one {
    &.column {
      width: 4.66666666667%;
    }
  }

  .one-half {
    &.column {
      width: 48%;
    }
  }
}

@media @min750 {
  .g-section {
    padding: 7rem 0 7rem;
  }
}

@media @min1000 {
  .g-section {
    padding: 4rem 0 5rem;
  }
}