/*------------------------------------------------------------------
# [Buttons]
------------------------------------------------------------------*/

.btn {
	display: inline-block;
	text-decoration: none;
	padding: 0 20px;
	height: 50px;
	border-radius: 8px;
	background-color: @white;
	border: solid 1px @btn-border;
	font-size: 12px;
	letter-spacing: 1.5px;
	line-height: 52px;
	color: @gold;
	text-transform: uppercase;
	text-align: center;
	margin: 10px auto;
	.transition-spec(all,200ms,ease-in-out);

	&:hover {
		background-color: @gold;
	}

	@media @bp_1024 {
		border: 1.5px solid @gold;
	}

	&.btn-block {
		display: block;
	}

	&.btn-inverted {
		background-color: transparent;
		border: solid 1px #fff;

		&:hover {
			border: solid 1px @btn-border;
		}
	}

	&.btn-link {
		background: transparent;
		border: none;
		color: @grey;
	}

	&.btn-icon {
		padding-left: 0;
		padding-right: 0;

		img {
			display: inline-block;
			vertical-align: middle;
			width: 25px;
			height: 25px;
			margin: 0 10px 0 20px;
			float: none;
		}
	}

	&.btn-empty {
		background: transparent;
	}

	&:hover {
		background-color: @gold;
		color: @white;
	}
}

.show-full-list {
	text-transform: uppercase;
	font-size: 12px;
	line-height: 1.25;
	letter-spacing: 1.5px;
	color: @gold;
	text-decoration: none;
	line-height: 18px;

	@media @bp_768 {
		line-height: 28px;
	}

	img {
		width: 18px;
		vertical-align: bottom;
		margin-right: 5px;

		@media @bp_768 {
			width: 28px;
			margin-right: 10px;
		}
	}
}

.menu-more-btn {
	text-decoration: none;
	color: @gold;

	img,
	span {
		display: inline-block;
		vertical-align: middle;
	}

	img {
		margin-right: 10px;
	}
	span {
		text-transform: uppercase;
		color: @gold;
		font-size: 13px;
		line-height: 1.33em;
		max-width: 100px;
	}
}

