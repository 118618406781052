/*------------------------------------------------------------------
# [Main]
--------------------------------------------------------------------

Main [.main]

------------------------------------------------------------------*/

// Main [.main]

.main {
  .clearfix();
  clear: both;
  padding-top: 73px;
  padding-bottom: 40px;

  @media @bp_768 {
    padding-top: 100px;
  }

  @media @bp_1400 {
    padding-top: 100px;
  }
}

// home-slider

.home-slider {

  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background: #e6e6e6;
  background: linear-gradient(to right, #e6e6e6 1%, #f7f7f7 100%);

  @media @bp_1400 {
    overflow: hidden;
  }

  h1 {
    text-align: left;
    font-style: italic;
    .font-size(30);
    font-weight: 300;
    line-height: 40px;

    @media @bp_1400 {
      .font-size(30);
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  p {
    @media @bp_1400 {
      margin: 0;
      width: 100%;
    }
  }

  .home-slider-item {
    overflow: hidden;
    position: relative;
    padding: @spacer_mobile;

    @media @bp_768 {
      padding: 20px @spacer_tablet;
    }

    @media @bp_1400 {
      padding: 20px @spacer_desktop;
    }
  }

  .home-slider-banner {
    overflow: hidden;
    padding: 0;
    min-height: 310px;
    width: 100%;
    display: flex;
    margin: 0 auto;

    @media @bp_768 {
      min-height: 470px;
    }

    @media @bp_1400 {
      overflow: hidden;
      height: 500px;
      max-width: 1000px;
    }
  }

  .banner-fact {
    width: 60%;
    flex: 3;

    @media @bp_1400 {
      padding: 130px 0 130px 23px;

      p {
        max-width: 450px;
      }
    }
  }

  .banner-face {
    width: 40%;
    height: 100%;
    flex: 2;

    img {
      max-height: 100%;
      position: absolute;
      bottom: 0;
    }

    &.banner-face-left {
      order: -1;

      img {
        position: relative;
      }
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    list-style: none;

    li {
      background-image: none;
      display: inline-block;
      margin: 10px;

      button {
        outline: none;
        border: none;
        .rounded-corners(100%);
        background: #fff;
        font-size: 0;
        height: 12px;
        width: 12px;
        cursor: pointer;
        position: relative;

        &:after {
          content: " ";
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
          border: 1px solid @gold;
          .rounded-corners(100%);
        }
      }
    }

    li.slick-active {
      button {
        background: @gold;
      }
    }
  }
}

.action-area,
.action-area-mobile {
  padding-top: 23px;
  text-align: center;

  p {
    display: block;
    text-align: center;
    margin: 0;
    color: @gold;
  }

  @media @bp_1400 {
    a,
    p {
      display: inline-block;
      width: auto;
    }

    p {
      display: inline-block;
      width: auto !important;
      padding: 0 20px;
    }
  }
}

.action-area {
  display: none;

  @media @bp_768 {
    display: inline-block;
  }
}

.action-area-mobile {
  text-align: center;
  display: block;

  .btn {
    max-width: 240px;
    padding: 0 10px;
    letter-spacing: normal;
  }

  @media @bp_768 {
    display: none;
  }
}

// modules

.module {
  clear: both;
  padding: 25px 0;

  @media @bp_768 {
    max-width: 940px;
    margin: 0 auto;
  }

  &.specialist {
    padding-bottom: 0;
  }

  &.article-centered {
    padding-top: 0;
  }
}

.featured-list, .news {

  @media @bp_1400 {
    position: relative;
    width: auto;
  }

  h2 {

    @media @bp_1400 {
      position: relative;
    }
  }

  h3 {
    padding: 0;
    margin: 0 !important;
    font-style: italic;
    .font-size(16);

    @media @bp_768 {
      .font-size(20);
    }

    @media @bp_1400 {
      .font-size(22);
    }
  }

  ul {
    margin-bottom: 10px;
  }

  li {
    background-image: none;
    padding-left: 20px;
    list-style: none;
    position: relative;
    .font-size(12);
    font-weight: 300;
    letter-spacing: 0.7px;
    color: @grey;

    @media @bp_768 {
      padding-left: 40px;
      .font-size(13);
      font-weight: 300;
      letter-spacing: 0.8px;
      display: block;
    }

    &:nth-child(-n+3) {
      display: block;
    }

    &:hover {
      cursor: default;
    }

    &:before {
      content: '';
      position: absolute;
      top: 14px;
      left: 0;
      width: 15px;
      height: 0;
      border-bottom: 1px solid @gold;

      @media @bp_768 {
        width: 25px;
      }
    }

    &.active {
      color: @gold;

      &:before {
        width: 8px;
        left: 7px;

        @media @bp_768 {
          width: 8px;
          left: 17px;
        }
      }

      a {
        color: @gold;
      }
    }

    > a {
      line-height: 16px;
      padding-bottom: 4px;

      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0px;
        left: 0;
        background-color: @gold;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out 0s;
      }

      &:hover {

        &:after {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
  }
.news {
  li {
    &:before {
      border: none;
    }
  }
}
  &.featured-list-narrow {

    li {
      max-width: 200px;
    }
  }

  a {
    position: relative;
    display: inline-block;
    color: @grey;
    text-decoration: none;
  }

  p {
    width: 100%;
    max-width: 585px;
    margin: 5px 0 25px 0;
  }
}

ul.pricelist {
  list-style: none;
  overflow-x: hidden;

  li {
    background-image: none;
    position: relative;
    padding: 0;

    &:before {
      display: none;
    }

    span {
      color: @grey;
      background: #fff;
    }

    span.item {
      &:before {
        content: '....................................................................................................................................................................................................................';
        white-space: nowrap;
        width: 0;
        float: left;
      }
    }

    span.price {
      float: right;
    }

    small {
      display: block;
      margin-bottom: 10px;
    }
  }
}

.col-list {

  @media @bp_768 {
    column-count: 2;
    column-gap: 20px;
  }

  @media @bp_1024 {
    column-count: 3;
    column-gap: 20px;
  }
}

.specialisti {

  @media @bp_1400 {
    position: relative;
    width: auto;
  }

  h2 {
    margin: 23px 0;
    position: relative;

    @media @bp_1400 {

      &:before {
        .trailing-line();
      }
    }
  }

  figure {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  figcaption {
    text-align: left;
    width: 230px;
    margin: 0 auto;

    @media @bp_1024 {
      width: auto;
    }
  }

  ul {

    li {
      width: 100%;
      list-style: none;
      margin: 0 auto 35px auto;
      text-align: left;
      max-width: 230px;

      @media @bp_480 {
        display: block;
        float: left;
        max-width: none;
        width: 50%;
        padding: 10px;
        .box-sizing();

        &:nth-child(2n+1) {
          clear: both;
        }

        &:first-child {
          margin-left: 0;
        }
      }

      @media @bp_1024 {
        width: 20%;

        &:nth-child(2n+1) {
          clear: none;
        }

        &:nth-child(4n+1) {
          //clear: both;
        }
      }

      &:first-child {
        margin-top: 20px;

        @media @bp_480 {
          margin-top: 0;
        }
      }

      img {
        max-width: 154px;
        margin: 0 auto;

        @media @bp_1400 {
          max-width: 180px;
        }
      }

      strong {
        display: block;
        .font-size(13);
        font-weight: 700;
        letter-spacing: 0.6px;
        text-transform: uppercase;

        @media @bp_768 {
          .font-size(12);
        }
      }

      span {
        display: block;
        .font-size(13);
        font-weight: 300;
        letter-spacing: 0.5px;
        line-height: 16px;

        @media @bp_768 {

        }
      }
    }
  }
}

.poslovalnice {

  h2 {
    margin: 23px 0;
    position: relative;

    @media @bp_1400 {
      text-transform: uppercase;
    }
  }

  @media @bp_1400 {

    h2:before {
      .trailing-line();
    }
  }

  .poslovalnice-list {

    @media @bp_1400 {
      width: 50%;
    }

    ul {

      @media @bp_1400 {
        margin: 100px 0;
      }

      li {
        list-style: none;
        max-width: 190px;
        margin: 0 auto 10px auto;
        font-family: Georgia;
        font-size: 18px;
        font-style: italic;
        line-height: 1.67;
        letter-spacing: 0.5px;
        text-align: center;

        @media @bp_1400 {
          font-size: 24px;
          line-height: 1.25;
          max-width: none;
        }

        img {
          vertical-align: text-top;
          height: 20px;
          width: 14px;
          margin-right: 10px;

          @media @bp_1400 {
            height: 25px;
            width: 17px;
          }
        }
      }
      li:first-child {
        margin-top: 20px;
      }
    }

    p {
      margin-top: 23px;

      @media @bp_1400 {
        padding-right: 40px;
      }
    }

    @media @bp_1400 {
      width: 49%;
      display: inline-block;
    }
  }

  .poslovalnice-gallery {

    @media @bp_768 {
      width: 100%;
      max-width: 710px;
      margin: 0 auto;
      position: relative;
    }

    @media @bp_1400 {
      position: absolute;
      right: 0px;
      width: 50%;
      max-width: 715px;
      display: inline-block;
      vertical-align: top;
    }

    img {
      max-width: 100%;
      width: 100%;
    }

    .poslovalnice-slider {
      //display: none;

      @media @bp_768 {
        display: block;
      }
    }

    .one-office {
      max-width: 100%;
      margin-top: 10px;
      float: left;
      margin-bottom: 20px;
      max-width: 715px;

      @media @bp_768 {
        display: none;
      }
    }

    figure {
      margin: 0;
      padding: 0;
    }

    figcaption {
      display: none;

      @media @bp_1400 {
        display: block;
        text-transform: uppercase;
        padding-left: 80px;
        padding-top: 30px;
      }
    }

    #next-poslovalnica,
    #prev-poslovalnica {
      outline: none;
      background: none;
      border: none;
      cursor: pointer;
      margin-top: 10px;
    }

    #prev-poslovalnica {
      margin-right: 10px;
    }

    .slick-dots {
      display: none;

      @media @bp_768 {
        position: absolute;
        right: 0px;
        bottom: -30px;

        li {
          display: inline-block;
          margin-left: 23px;

          &:first-child {
            margin-left: 0;
          }

          &.slick-active {

            button {
              background-color: @white;
            }
          }
        }

        button {
          -webkit-appearance: none;
          .font-size(0);
          border: 1px solid @grey;
          background-color: @grey;
          width: 5px;
          height: 5px;
          margin: 0;
          padding: 0;
          display: block;
          .hide-text();
        }
      }

      @media @bp_1400 {
        bottom: 0px;
      }
    }

    .arrows {
      display: none;

      @media @bp_1400 {
        display: block;
        position: relative;
        top: -35px;
        z-index: 20;
        button {
          display: inline;
        }
      }
    }
  }
}

.features-box-closed {
  position: relative;
  width: 100%;
  min-height: 80px;
  background-color: @white-dark;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  @media @bp_768 {
    background: transparent;
    cursor: default;
  }

  > span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 1.5px;
    font-style: normal;
    font-family: 'Roboto', sans-serif;
    text-align: center;
    text-transform: uppercase;
    color: @gold;
    transition: 0.2s ease-in-out;

    @media @bp_768 {
      opacity: 0;
      display: none;
    }
  }

  > h2,
  > ul,
  > p,
  > div {
    display: none;
    opacity: 0;
    transition: 0.2s ease-in-out;

    @media @bp_768 {
      opacity: 1;
      display: block;
    }
  }

  > h2 {
    text-transform: uppercase;
  }
}

.features-box-open {
  background: transparent;
  cursor: default;

  > span {
    display: none;
    opacity: 0;
  }

  > h2,
  > ul,
  > p,
  > div {
    display: block;
    opacity: 1;
  }
}

// testimonials

.featured-testimonials {
  position: relative;

  h3 {
    font-family: Georgia;
    font-size: 24px;
    font-weight: normal;
    font-style: italic;
    line-height: 1.38;
    letter-spacing: 1.1px;
    color: @gold;
    padding-bottom: 30px;

    @media @bp_1400 {
      line-height: 1.5;
      margin: 20px 0;
    }

    strong {
      font-size: 50px;
      line-height: 0.68;
      letter-spacing: 2.4px;
      font-weight: normal;
      display: block;
      padding-bottom: 5px;

      @media @bp_1400 {
        font-size: 82px;
        padding-bottom: 15px;
      }
    }
  }

  &:before {

    @media @bp_1400 {
      content: "";
      display: block;
      position: absolute;
      width: 440px;
      margin-left: -220px;
      height: 1px;
      background-color: @gold;
      top: 0px;
      left: 50%;
    }
  }
}

.testimonial {
  border-top: 1px solid @gold;
  border-bottom: 1px solid @gold;
  padding: 40px 0;
  margin: 0 0 60px 0;
  text-align: center;
  .clearfix;

  @media @bp_768 {
    text-align: left;
  }

  figcaption,
  span {
    color: @gold;
  }

  p {
    margin: 15px 0;
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.8px;
    text-align: left;
  }

  img {
    margin: 0 auto;
    .rounded-corners(100%);
    width: 185px;
    height: 185px;

    @media @bp_768 {
      float: left;
      margin-right: 20px;
    }
  }
}

.featured-testimonials-left {

  @media @bp_1400 {
    width: 33%;
    display: inline-block;
    margin-top: 40px;
    vertical-align: top;
  }
}

.featured-testimonials-right {
  position: relative;
  padding-top: 30px;

  @media @bp_1400 {
    width: 66%;
    display: inline-block;
    margin-top: 40px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 240px;
    height: 1px;
    background-color: @gold;
    top: 0px;
    left: 50%;
    margin-left: -120px;

    @media @bp_768 {
      width: 440px;
      margin-left: -220px;
    }

    @media @bp_1400 {
      display: none;
    }
  }

  .featured-testimonials-slider {
    position: relative;
  }

  .testimonial {
    border: none;
    margin-bottom: 0;
    padding: 0;

    img {
      width: 110px;
      height: 110px;
      margin: 0 auto 20px auto;
      display: block;
      float: none;

      @media @bp_1400 {
        margin: 0 100px 0 65px;
        width: 185px;
        height: 185px;
      }
    }
  }

  #next-testimonial,
  #prev-testimonial {
    position: absolute;
    top: 70px;
    outline: none;
    background: none;
    border: none;
    cursor: pointer;

    @media @bp_1400 {
      top: 110px;
      //transform: translateY(-50%);
    }
  }

  #next-testimonial {
    right: 50%;
    margin-right: -100px;

    @media @bp_1400 {
      left: 280px;
      right: auto;
    }
  }

  #prev-testimonial {
    left: 50%;
    margin-left: -100px;

    @media @bp_1400 {
      left: 10px;
      margin-left: 0;
    }
  }
}

.footer-icons {
  text-align: center;
}

.footer-icons-list {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: left;
  width: 240px;
  padding-top: 30px;
  .clearfix;

  @media @bp_768 {
    text-align: center;
    width: auto;
    padding-top: 50px;
  }

  @media @bp_1400 {
    padding-top: 70px;
    margin-top: 30px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 240px;
    height: 1px;
    background-color: @gold;
    top: 0px;
    left: 50%;
    margin-left: -120px;

    @media @bp_768 {
      width: 440px;
      margin-left: -220px;
    }
  }
}

.footer-icon {
  vertical-align: top;
  margin-top: 20px;
  position: relative;
  text-align: left;
  height: 64px;
  padding-left: 74px;

  @media @bp_768 {
    text-align: center;
    padding-left: 0;
    height: auto;
  }

  @media @bp_1400 {
    width: 250px;
    margin: 0 20px;
    float: left;
  }

  .icon-circle {
    width: 64px;
    height: 64px;
    margin-right: 10px;
    background: @white-dark;
    .rounded-corners(100%);
    position: absolute;
    left: 0px;
    top: 0px;

    @media @bp_768 {
      height: 100px;
      width: 100px;
      position: relative;
      display: inline-block;
      margin: 0 auto;
      margin-bottom: 50px;
    }
  }

  a {

    &:hover {
      color: @gold;
    }
  }

  h3 {
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 1.52;
    letter-spacing: 1.8px;
    text-transform: uppercase;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0;
    margin: 0;

    @media @bp_768 {
      display: block;
      text-align: center;
      font-family: Georgia;
      font-size: 24px;
      font-style: italic;
      line-height: 1.25;
      letter-spacing: 0.6px;
      text-transform: none;
      max-width: none;
    }

    @media @bp_1400 {
      min-height: 60px;
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    height: 30px;

    @media @bp_768 {
      height: 45px;
    }
  }

  p {
    padding: 0 20px;
    margin: 0;
    display: none;

    @media @bp_768 {
      display: inline-block;
      width: 50%;
    }

    @media @bp_1400 {
      width: auto;
      line-height: 20px;
    }
  }
}

// simple gallery

.gallery-items {
  .clearfix;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    max-width: 30%;
    margin: 10px;

    img {
      width: 100%;
      display: block;
    }
  }
}

// list of specialists/doctors/employees

.specialists-list {
  .clearfix;

  img {
    float: left;
    margin-right: 10px;

    @media @bp_768 {
      margin-right: 80px;
    }
  }

  li {
    .clearfix;
    padding: 0 20px;
    clear: both;
    position: relative;
    list-style: none;
    margin-bottom: 45px;
  }

  li:before {

    @media @bp_768 {
      position: absolute;
      top: 2em;
      left: 135px;
      content: '';
      width: 85px;
      border-bottom: 1px solid @gold;
      z-index: -1;
    }
  }

  .name {
    text-transform: uppercase;
    display: block;
    font-size: 13px;
    font-weight: bold;
  }

  .title {
    display: block;
    font-size: 11px;
    margin-bottom: 10px;
  }
}

.need-help {
  padding: 25px;
  text-align: center;
  margin-bottom: 50px;

  @media @bp_1400 {
    margin-bottom: 100px;
  }

  p {
    padding: 10px 0;
    margin: 0;
  }

  .btn {
    clear: both;
    display: block;
    max-width: 250px;

    @media @bp_768 {
      display: inline-block;
      margin-left: 50px;
      max-width: none;
    }
  }
}

.article {
  max-width: 945px;
  margin: 0 auto;

  .article-centered {
    max-width: 600px;
    margin: 0 auto;

    > ul {
      margin: 10px 0;

      li {
        list-style: none;
        position: relative;
        padding-left: 20px;
        font-weight: 300;
        line-height: 1.42;
        letter-spacing: 0.9px;
        background-image: url('../images/bullet.png');
        background-size: 10px;
        background-repeat: no-repeat;
        background-position: 3px 2px;
      }

      &.featured-list {

        li {
          background-image: none;
          padding-left: 20px;
          .font-size(12);
          font-weight: 300;
          letter-spacing: 0.7px;

          @media @bp_768 {
            padding-left: 40px;
            .font-size(13);
            font-weight: 300;
            letter-spacing: 0.8px;
            display: block;
          }
        }
      }
    }
  }

  .featured-list {

    h3 {
      color: @grey;
    }

    li {
      background-image: none;
      display: block;

      &:hover {
        cursor: default;
      }
    }
  }

  .mid-boxed {
    position: relative;
    margin-bottom: 2px;

    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      left: -100px;
      right: -100px;
      top: 0px;
      bottom: 0px;
      background-color: @white-dark;
    }
  }

  .emphasis {
    margin-top: 0px;

    &:before {
      top: 0px;
    }
  }

  .footer-icons {

    ul {

      &:before {
        display: none;
      }
    }
  }

  .empty {
    width: 150px;
    height: 135px;
    float: left;
    margin-right: 10px;
    display: none;

    @media @bp_768 {
      margin-right: 80px;
      display: unset;
    }
  }
  &.view {
    padding: 20px;

    .article-centered {
      padding-top: 0;
    }
    .specialist-info {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .news-head {
      display: flex;
      flex-wrap: wrap;

      .profile-pic, .specialist-content {
        position: relative;
        width: 100%;
      }

      .profile-pic {
        flex: 0 0 40%;
        max-width: 40%;
      }

      .specialist-content {
        flex: 0 0 60%;
        max-width: 60%;

        .specialist-info {
          padding: 0;
        }

        div {
          margin-left: 30px;
        }
      }
    }

    @media @bp_768_max {
      .news-head {
        max-width: 600px;
        margin: 0 auto;

        .profile-pic {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .specialist-content {
          flex: 0 0 100%;
          max-width: 100%;
          margin-top: 20px;

          div {
            margin-left: 0;
          }
        }
      }
    }
  }
}

.lead {
  position: relative;
  padding-bottom: 40px;

  p {
    font-size: 19px;
    font-weight: 300;
    line-height: 1.63;
    letter-spacing: 1.3px;
    text-align: center;
    margin-bottom: 0;
  }

  p:after {
    content: '';
    max-width: 320px;
    border-bottom: 1px solid @gold;
    display: block;
    margin: 25px auto 0 auto;

    @media @bp_1400 {
      margin: 45px auto 0 auto;
    }
  }

}

.specialist,
.poseg {
  .clearfix;

  img {
    margin-right: 60px;
  }

  .profile-pic {

    @media @bp_768 {
      float: left;
      width: 40%;
    }

    @media @bp_1400 {
      width: auto;
      margin-left: -154px;
    }

    p {
      max-width: 340px;
      margin-left: 0;
      margin-right: 60px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .btn {
    margin: 0 5px;
  }

  .specialist-name-title,
  .poseg-name-title {
    h1 {
      text-align: left;
      margin: 0;
      margin-bottom: 15px;
    }

    .title {
      text-transform: uppercase;
    }
  }

  .specialist-info,
  .poseg-info {
    background-color: @white-dark;
    position: relative;
    padding: 20px;
    margin: 0 -20px;

    @media @bp_768 {
      background-color: transparent;
      .box-sizing();
      float: left;
      width: 60%;
      margin: 0;
    }

    @media @bp_1400 {
      background-color: transparent;
      float: none;
      width: auto;
      padding: 40px 40px 40px 250px;
      margin-top: 30px;
      min-height: 120px;
    }

    p {
      margin-bottom: 15px;
      float: left;
    }
  }

  .poseg-info {
    padding-bottom: 70px;

    .poseg-location,
    .poseg-dr {
      display: block;
      vertical-align: middle;
      margin-bottom: 15px;

      &:before {
        content: '';
        position: relative;
        display: inline-block;
        margin-right: 10px;
        width: 25px;
        height: 25px;
        vertical-align: middle;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .center {
      text-align: center;
      display: block;

      .btn {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .poseg-location {
      text-transform: uppercase;
      &:before {
        background-image: url('../images/icon-location-black.svg');
      }
    }

    .poseg-dr {
      &:before {
        background-image: url('../images/icon-doctor.svg');
      }
    }

    .time-price-wrapper {
      float: left;
      display: block;
      width: 100%;
      margin-bottom: 40px;
    }

    .poseg-time,
    .poseg-clock,
    .poseg-price {
      position: relative;
      float: left;
      text-transform: uppercase;
      display: inline-block;
      padding-top: 20px;
      margin-right: 50px;

      > span {
        display: block;
        margin-top: 5px;
        text-transform: none;
      }
    }

    .poseg-time,
    .poseg-clock {

      span:before {
        content: '';
        position: relative;
        display: inline-block;
        margin-right: 10px;
        width: 25px;
        height: 25px;
        vertical-align: middle;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }

    .poseg-time {

      span:before {
        background-image: url('../images/icon-calendar-black.svg');
      }

    }

    .poseg-clock {

      span:before {
        background-image: url('../images/trajanje-posega-2.svg')
      }
    }

    /*.poseg-price {

        span {
            line-height: 25px;
            font-size: 30px;
            font-weight: bold;
        }
    }*/
  }

  .specialist-info:after,
  .poseg-info:after {

    @media @bp_768 {
      background-color: @white-dark;
      content: '';
      top: 0;
      right: -9999%;
      height: 110%;
      position: absolute;
      z-index: -1;
      left: -150px;
    }

    @media @bp_1400 {
      height: 100%;
      left: -80px;
    }
  }

  .more {
    float: right;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.25;
    letter-spacing: 1.5px;
    position: relative;
    display: inline-block;
    color: @grey;
    text-decoration: none;
    margin: 20px 0 0 30px;

    img {
      margin-right: 10px;
    }
  }

  .marg {
    margin: 20px;
  }
}

// before-after

.before-after {
  margin: 0 auto;
  margin-bottom: 0; //100px
  text-align: center;
}

.before-after-wrapper {
  position: relative;
  border: none;
  margin-bottom: 0;
  padding: 0;
  vertical-align: middle;
  text-align: center;
  position: relative;
  max-width: 500px;
  width: 100%;
  display: inline-block;

  li {
    background-image: none;

    div {
      margin: 0 auto;
      display: block;
    }
  }

  img {
    margin: 0 auto 20px auto;
    display: block;
    float: none;

    @media @bp_1400 {
      margin: 0 auto;
    }
  }
}

#next-case,
#prev-case {
  display: inline-block;
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  margin-top: -20px;
}

#prev-case {
  left: -30px;
}

#next-case {
  right: -30px;
}

// section with an emphasis (background faded rectangle)

.emphasis {
  position: relative;

  @media @bp_768 {
    margin-top: 100px;
  }

  @media @bp_1400 {
    margin-top: 200px;
  }

  h1, h2, h3, h4, h5, h6, h7 {
    margin-top: 40px;
  }
}

.emphasis.emphasis-short {
  h1, h2, h3, h4, h5, h6, h7 {
    margin-top: 130px;
  }
}

.emphasis:before {
  content: '';
  position: absolute;
  top: 20px;
  left: -9999%;
  right: 65%;
  height: 150px;
  background: @white-dark;
  z-index: -1;

  @media @bp_768 {
    top: -20px;
    height: 250px;
  }

  @media @bp_1400 {
    top: -120px;
    height: 350px;
  }
}

.emphasis.emphasis-short:before {
  height: 330px;
}

.emphasis.emphasis-narrow:before {
  right: 80%;
}

// connecting lines
.connected {
  margin-bottom: -80px;

  &:after {
    content: '';
    position: relative;
    height: 120px;
    left: 50%;
    border-left: 1px solid @gold;
    display: block;
    margin-top: 20px;
  }

  &.poseg {
    margin-bottom: -30px;

    &:after {
      content: '';
      position: relative;
      height: 80px;
      left: 50%;
      border-left: 1px solid @gold;
      display: block;
      margin-top: -65px;
    }
  }
}

.centered-title {
  vertical-align: middle;
  text-align: center;

  img {
    vertical-align: middle;
  }
}

// related-content
.related-content {
  text-align: center;

  h2 {
    text-align: left;

    @media @bp_768 {
      padding-bottom: 20px;
    }
  }

  .related-content-list {
    display: inline-block;

    @media @bp_768 {
      width: 100%;
    }
  }

  figure {
    margin: 0;
    padding: 0;
  }

  .block-btn {
    text-align: center;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-transform: uppercase;
    line-height: 16px;
    background-color: transparent;

    img {
      margin: 0 auto;
      display: block;
      padding-bottom: 10px;
    }
  }

  .related-content-item {
    width: 100%;
    margin-bottom: 30px;
    min-height: 300px;
    position: relative;
    padding-left: 0;

    &:before {
      display: none;
    }

    @media @bp_768 {
      float: left;
      width: 32.26%;
      margin-left: 1.6%;

      &:first-child {
        margin-left: 0;
      }
    }

    figcaption {
      text-align: left;

      h3 {
        padding: 10px 0 0 20px;
        margin: 0;
        list-style: none;
        position: relative;
        .font-size(12);
        font-weight: 300;
        line-height: 2.5em;
        letter-spacing: 0.7px;
        color: @grey;
        text-transform: uppercase;
        line-height: 16px;

        @media @bp_768 {
          padding-left: 40px;
          .font-size(13);
          font-weight: 300;
          letter-spacing: 0.8px;
          display: block;
        }

        &:before {
          content: '';
          position: absolute;
          top: 1.5em;
          left: 0;
          width: 15px;
          height: 0;
          border-bottom: 1px solid @gold;

          @media @bp_768 {
            top: 1.5em;
            left: 0;
            width: 25px;
          }
        }
      }

      a {
        display: inline-block;
        position: relative;
        padding-bottom: 5px;

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0px;
          left: 0;
          background-color: @gold;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out 0s;
        }

        &:hover {

          &:after {
            visibility: visible;
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

.list-all {
  .clearfix;

  &:before {
    content: '';
    max-width: 320px;
    border-top: 1px solid @gold;
    display: block;
    margin: 0 auto;
    text-align: center;

    @media @bp_1400 {
      margin: 0 auto;
    }
  }

  .block-btn {
    width: 210px;
    height: 210px;
  }
}

// search-form

.search-form {

  @media @bp_1400 {
    padding-left: 40px;
  }

  h1 {
    text-align: left;

    @media @bp_1400 {
      margin-left: -40px;
    }
  }

  h2 {
    padding-top: 0px;
    margin-top: 0px;
    .font-size(22);
  }

  .search-form-wrapper {
    position: relative;
    max-width: 390px;
  }

  input[type="search"] {
    height: 64px;
    padding-right: 70px;
  }

  input[type="submit"] {
    background-color: transparent;
    border: none;
    background-image: url('../images/icon-search.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px;
    width: 68px;
    height: 68px;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  &.emphasis {
    margin-top: 0;

    &:before {
      top: 50px;
      right: 105%;
    }
  }

  .search-results-item {

    .description {
      border-top: 1px solid #e5ded3;
      border-bottom: 1px solid #e5ded3;
      margin-bottom: 20px;

      p {
        padding: 10px 0;
        margin: 0;
      }
    }

    .pricelist-list {
      padding-left: 20px;

      @media @bp_768 {
        padding-left: 30px;
      }

      @media @bp_1400 {
        padding-left: 50px;
      }
    }
  }
}

.form-steps {
  background-color: @white-dark;
  padding: 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: -2000px;
    right: -2000px;
    top: 0px;
    bottom: 0px;
    background-color: @white-dark;
  }

  ul {
    padding: 50px 0;
    width: 100%;
    position: relative;
    .clearfix;
  }

  h7 {
    clear: both;
    display: block;
    text-align: center;
    padding: 10px;
    margin: 0;
  }

  li {
    background-image: none;
    display: none;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    padding-left: 0;

    &:before {
      display: none;
    }

    @media @bp_768 {
      display: block;
      float: left;
      width: 33.33%;
    }

    &.selected {
      display: block;

      span {
        color: @white;
        background-color: @gold;
      }
    }
  }

  span {
    .rounded-corners(100%);
    width: 96px;
    height: 96px;
    line-height: 90px;
    display: inline-block;
    background-color: @white;
    font-style: italic;
    .font-size(48);
    font-family: @base-heading-font;
    color: @gold;
  }
}

.form-steps-navigation {
  text-align: center;
}

.alt-menu {
  text-align: center;
  overflow: visible;
  padding: 0 0 20px;

  ul {
    .box-sizing;
    display: inline-block;
    border: 1px solid @gold;
    background-color: @white-dark;
    width: 100%;
    .font-size(0);

    @media @bp_768 {
      background-color: transparent;
      width: auto;
      border: none;
      border-top: 1px solid @gold;
    }
  }

  li {
    background-image: none;
    .box-sizing;
    width: 100%;
    border-top: 1px solid @gold;
    padding: 7px 30px;
    position: relative;
    text-transform: uppercase;
    .font-size(12);
    line-height: 16px;
    margin: 0;
    top: -1px;

    &:before {
      display: none;
    }

    @media @bp_768 {
      width: auto;
      display: inline-block;
      padding: 5px 20px;
    }

    @media @bp_1400 {
      padding: 5px 25px;
    }

    &.selected {

      a {
        color: @gold;

        &:before {
          content: "";
          position: absolute;
          top: -12px;
          width: 100%;
          height: 4px;
          background-color: @gold;

          @media @bp_768 {
            top: -10px;
          }
        }
      }
    }

    a {
      position: relative;

      &:hover {
        color: @gold;

        &:before {
          content: "";
          position: absolute;
          top: -12px;
          width: 100%;
          height: 4px;
          background-color: @gold;

          @media @bp_768 {
            top: -10px;
          }
        }
      }
    }
  }
}

.tabs {
  display: none;
  .clearfix;

  @media @bp_768 {
    display: block;
    line-height: 0px;
    padding: 0;
    margin: 0;
    .clearfix;
    text-align: center;
    position: relative;
  }

  .tabs-list {
    display: inline-block;
    float: left;
  }

  li {
    background-image: none;
    margin-left: 10px;
    text-align: center;
    float: left;
    padding: 10px;
    .font-size(14);
    line-height: 20px;
    text-transform: uppercase;
    cursor: pointer;

    @media @bp_1024 {
      .font-size(16);
      padding: 10px 20px;
    }

    &:first-child {
      margin-left: 0;
    }

    &.selected,
    &:hover {
      background-color: @white-dark;
    }
  }
}

.filter {
  text-align: left;
  display: inline-block;
  width: 100%;

  @media @bp_768 {
    display: none;
  }

  .form-row {
    .box-sizing;
    padding-left: 0;
  }
}

.tab-content {
  .featured-list {
    h2 {
      .font-size(30);
    }
  }
}

.ul-grid {
  ul {
    li {
      width: 100%;
      list-style: none;
      margin: 0 auto 0px auto;
      text-align: left;
      max-width: 230px;
      h3 {
        color: @grey;
      }
      @media @bp_480 {
        display: block;
        float: left;
        max-width: none;
        width: 50%;
        padding: 0 20px 0 0;
        .box-sizing();

        &:nth-child(2n+1) {
          clear: both;
        }

        &:first-child {
          margin-left: 0;
        }
      }

      @media @bp_1024 {
        width: 25%;

        &:nth-child(2n+1) {
          clear: none;
        }

        &:nth-child(4n+1) {
          clear: both;
        }
      }

      &:first-child {
        margin-top: 20px;

        @media @bp_480 {
          margin-top: 0;
        }
      }

      img {
        max-width: 154px;
        margin: 0 auto;

        @media @bp_1400 {
          max-width: 240px;
        }
      }
      .selectable {
        border: @input-border-width @input-border-style #cccccc;
        padding: 20px 10px;
        margin: 0 auto;
        text-align: center;

        &.active, &:hover {
          background: @gold;
          color: @white;
          a, h3 {
            color: @white;
          }
        }

        @media @bp_1400 {
          max-width: 240px;
        }
      }

      strong {
        display: block;
        .font-size(13);
        font-weight: 700;
        letter-spacing: 0.6px;
        text-transform: uppercase;

        @media @bp_768 {
          .font-size(12);
        }
      }
      a, h3 {
        :hover {
          background: @gold;
          color: @white
        }
      }
      span {
        display: block;
        .font-size(13);
        font-weight: 300;
        letter-spacing: 0.5px;
        line-height: 16px;

        @media @bp_768 {

        }
      }
    }
  }
}

.news {
  padding:0;
  h2:before {
    .trailing-line();
  }

  .news-flex {
    display: flex;
    margin-top: 50px;
    flex-wrap: wrap;
    justify-content: space-between;
    .news-column {
      width: 30%;
      margin-right: 3.5%;
      padding-left: 0;
      display: inline;
      &:before {
        border: 0 none;
      }
      figure {
        margin: 0;
        min-height: 188px;
      }
      &.last {
        margin-right: 0;
      }
      &.left {
        float: left;
        background-color: @white;
        transition: 0.5s;
        cursor: pointer;

        .img {
          margin: 0;
        }

        .caption {
          margin: 15px 5px 5px 5px;
        }
      }

      &.left:hover {
        background-color: @grey-light;
        transition: 0.5s;
      }

      &.right {
        margin-left: 15px;
        background-color: @white;
        transition: 0.5s;

        .preview {
          padding: 10px 30px;
          cursor: pointer;

          div {
            width: 40px;
            border-top: 1px solid @gold;
          }

          a {
            margin-top: 10px;
          }

          p {
            max-width: unset;
          }
        }

        .preview:hover {
          background-color: @grey-light;
          transition: 0.5s;
        }

        .more {
          text-transform: uppercase;
          font-size: 12px;
          line-height: 1.25;
          letter-spacing: 1.5px;
          position: relative;
          display: inline-block;
          color: @grey;
          text-decoration: none;
          margin: 10px 0 0 30px;

          img {
            margin-right: 10px;
          }
        }

      }
    }

    a {
      color: unset;
    }
  }
}
