
/* Tables */

table {
	.clearfix();
	margin: 0 0 16px;
	border-collapse: collapse;
	border-spacing: 0;
	border: none;
	width: 100%;
	.font-size(16);
	line-height: 1.2;

	caption {
		color: @base-color;
	}
}

thead {
	th {
		background: #fff;
		text-align: center;
	}
}

th {
	padding: 10px 16px;
}

tbody th {
	text-align: left;
	font-weight: bold;
	vertical-align: middle;
}

td {
	padding: 14px 17px 10px;
	vertical-align: top;
	border-collapse: collapse;
}

table {
	border-bottom: 1px solid @btn-border;
	border-top: 1px solid @btn-border;
	width: 100%;

	thead {
		font-family: Georgia;
		.font-size(14);
		font-weight: normal;
		font-style: italic;
		line-height: 1.2;
		color: @gold;

		@media @bp_768 {
			.font-size(16);
			line-height: 1.4;
		}

		@media @bp_1400 {
			.font-size(22);
			line-height: 1.5;
		}

		th,
		td {
			padding-top: 10px;
			padding-bottom: 10px;

			@media @bp_768 {
				padding-top: 20px;
				padding-bottom: 20px;
			}
		}
	}

	tbody {
		.font-size(12);
		font-weight: 300;
		line-height: 1.4;
		letter-spacing: 0.9px;

		@media @bp_768 {
			.font-size(14);
			line-height: 1.6;
		}

		@media @bp_1400 {
			.font-size(16);
			line-height: 1.8;
		}

		tr:nth-child(odd) {
			background: @white-dark;
		}
	}

	td,
	th {
		padding: 10px 5px;
		text-align: center;

		@media @bp_768 {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}