/*------------------------------------------------------------------
# [Layout]
------------------------------------------------------------------*/

.grid {
	.clearfix();
	clear: both;
	padding: 0px;
	margin: 0px;

	.col {
		display: block;
		margin: 0;

		&:first-child {
			margin-left: 0 !important;
		}

		@media @bp_768 {
			float: left;
			margin: 0 0 0 1.6%;
		}
	}
}