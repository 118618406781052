.make-transitions(@base, @names) {

    // We'll make a single rule for all transitions that
    // sets the animation duration. This is basically the
    // stock 'animate' class from Animate.css.
    @classesJoin: replace(~'@{names}', ', ', '-enter-active, .@{base}', g);

    //.@{base}-enter-active, .@{base}-leave-active,
    //.@{base}@{classesJoin}-leave-active {
    //    animation-duration: @animationDuration;
    //    animation-fill-mode: both;
    //}

    .@{base}-enter-active, .@{base}In,
    .@{base}-leave-active, .@{base}Out {
        animation-duration: @animationDuration;
        animation-fill-mode: both;
    }

    // Entrance/Exit for the base class
    .@{base}-enter-active, .@{base}In {
        animation-name: ~'@{base}In';
    }

    .@{base}-leave-active, .@{base}Out {
        animation-name: ~'@{base}Out';
    }

     // Loop through the different animations, and set
     // the enter/leave animation names for each class.
    .-(@i: length(@names)) when (@i > 0) {

        @name: extract(@names, @i);
        .@{base}@{name}-enter-active, .@{base}In@{name},
        .@{base}@{name}-leave-active, .@{base}Out@{name} {
            animation-duration: @animationDuration;
            animation-fill-mode: both;
        }

        .@{base}@{name}-enter-active, .@{base}In@{name} {
            animation-name: ~'@{base}In@{name}';
        }
        .@{base}@{name}-leave-active, .@{base}Out@{name} {
            animation-name: ~'@{base}Out@{name}';
        }
        .-((@i - 1));

    } .-;
}