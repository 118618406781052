.hours {
  display: block;
  width: 100%;
  &.ul-grid {
    li {
      margin-bottom: 20px;

      @media @bp_1024 {
        width: 33%;

        &:nth-child(2n+1) {
          clear: none;
        }

        &:nth-child(3n+1) {
          clear: both;
        }
      }
      .selectable {
        border-color: gray;
      }
    }
  }
}
.hours:after {
  content: '';
  display: block;
  clear: both;
}
.hours .hour {
  transition: all 333ms ease-in-out;
  cursor: pointxer;
  white-space: nowrap;
}

.iti-flag {
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("../images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("../images/flags@2x.png")
  }
}

.form-date {
  .form-group {
    float: left;
    width: 100px;
    margin-right: 20px;
    margin-bottom: 0;
    clear: none;
  }
  .label {
    display: block;
    padding-bottom: 2px;
  }
  input {
    width:100%
  }
}
.intl-tel-input {
  display: block;
}
.col-1-2, .col-2-2 {
  float:left;
  width: 50%
}
.col-1-2 .responsive-calendar {
  margin-right: 20px;
}