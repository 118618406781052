.rotate3d(@x; @y; @z; @deg){
  transform: rotate3d(@x, @y, @z, @deg);
}
.scale3d (@x; @y; @z){
  transform: scale3d(@x, @y, @z);
}

@import 'flip';
@import 'flipInY';
@import 'flipInX';
@import 'flipOutX';
@import 'flipOutY';

@flips: inY, inX, outX, outY;

.make-transitions(flip, @flips);
