/*------------------------------------------------------------------
# [Mixins]
------------------------------------------------------------------*/

// fonts

.font-size(@font-size: 16){
	@rem: (@font-size / 10);
	font-size: @font-size * 1px;
	font-size: ~"@{rem}rem";
}

.text-overflow() {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

// rounded corners

.rounded-corners (@radius: 5px) {
	-webkit-border-radius: @radius;
	-moz-border-radius: @radius;
	-ms-border-radius: @radius;
	-o-border-radius: @radius;
	border-radius: @radius;
}

// trailing line

.trailing-line() {
	content: '';
	position: absolute;
	bottom: 8px;
	margin-right: 10px;
	left: -9000px;
	right: 100%;
	border-bottom: 1px solid @gold;
}

// gradients

.horizontal(@startColor: @white, @endColor: @lightergrey) {
	background-color: @endColor;
	background-image: -webkit-gradient(linear, 0 0, 100% 0, from(@startColor), to(@endColor));
	background-image: -webkit-linear-gradient(left, @startColor, @endColor);
	background-image: -moz-linear-gradient(left, @startColor, @endColor);
	background-image: -ms-linear-gradient(left, @startColor, @endColor);
	background-image: -o-linear-gradient(left, @startColor, @endColor);
	background-image: linear-gradient(left, @startColor, @endColor);
	background-repeat: repeat-x;
}

.vertical(@startColor: @white, @endColor:  @lightergrey) {
	background-image: -webkit-gradient(linear, 0 0, 0 100%, from(@startColor), to(@endColor));
	background-image: -webkit-linear-gradient(top, @startColor, @endColor);
	background-color: @endColor;
	background-image: -moz-linear-gradient(top, @startColor, @endColor);
	background-image: -ms-linear-gradient(top, @startColor, @endColor);
	background-image: -o-linear-gradient(top, @startColor, @endColor);
	background-image: linear-gradient(top, @startColor, @endColor);
	background-repeat: repeat-x; 
}

.vertical_50(@startColor: @white, @endColor:  @lightergrey) {
	background: @startColor;
	background: -moz-linear-gradient(top, @startColor 0%, @startColor 50%, @endColor 51%, @endColor 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,@startColor), color-stop(50%,@startColor), color-stop(51%,@endColor), color-stop(100%,@endColor));
	background: -webkit-linear-gradient(top, @startColor 0%,@startColor 50%,@endColor 51%,@endColor 100%);
	background: -o-linear-gradient(top, @startColor 0%,@startColor 50%,@endColor 51%,@endColor 100%);
	background: -ms-linear-gradient(top, @startColor 0%,@startColor 50%,@endColor 51%,@endColor 100%);
	background: linear-gradient(top, @startColor 0%,@startColor 50%,@endColor 51%,@endColor 100%);
}

.directional(@startColor: @white, @endColor: @lightergrey, @deg: 45deg) {
	background-color: @endColor;
	background-image: -moz-linear-gradient(@deg, @startColor, @endColor);
	background-image: -ms-linear-gradient(@deg, @startColor, @endColor);
	background-image: -webkit-linear-gradient(@deg, @startColor, @endColor);
	background-image: -o-linear-gradient(@deg, @startColor, @endColor);
	background-image: linear-gradient(@deg, @startColor, @endColor);
	background-repeat: repeat-x;
}

// .box-shadow(HORIZONTAL VERTICAL BLUR COLOR))

.box-shadow(@shadow: 0 1px 3px rgba(0,0,0,.25)) {
	-webkit-box-shadow: @shadow;
	-moz-box-shadow: @shadow;
	box-shadow: @shadow;
}

// .drop-shadow(HORIZONTAL, VERTICAL, BLUR, ALPHA);

.drop-shadow(@x-axis: 0, @y-axis: 1px, @blur: 2px, @alpha: 0.1) {
	-webkit-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
	-moz-box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
	box-shadow: @x-axis @y-axis @blur rgba(0, 0, 0, @alpha);
}

// .opacity(VALUE);

.opacity(@opacity:1) {
	@freaking-ie: @opacity * 100;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=@{freaking-ie})";
	filter: alpha(opacity=@freaking-ie);
	-webkit-opacity: @opacity;
	-moz-opacity: @opacity;
	opacity: @opacity;
}

// .rotate(VALUEdeg);

.rotate(@deg) {
	-webkit-transform: rotate(@deg);
	-moz-transform: rotate(@deg);
	-ms-transform: rotate(@deg);
	-o-transform: rotate(@deg);
	transform: rotate(@deg);
}

// .scale(VALUE);

.scale(@ratio) {
	-webkit-transform: scale(@ratio);
	-moz-transform: scale(@ratio);
	-ms-transform: scale(@ratio);
	-o-transform: scale(@ratio);
	transform: scale(@ratio);
}

// .skew(VALUE, VALUE);

.skew(@x: 0, @y: 0) {
	-webkit-transform: skew(@x, @y);
	-moz-transform: skew(@x, @y);
	-ms-transform: skew(@x, @y);
	-o-transform: skew(@x, @y);
	transform: skew(@x, @y);
}

// .transition(PROPERTY DURATION DELAY(OPTIONAL) TIMING-FINCTION);

.transition(@transition) {
	-webkit-transition: @transition;
	-moz-transition: @transition;
	-ms-transition: @transition;
	-o-transition: @transition;
	transition: @transition;
}

.transition_menu (@transition) {
//	-webkit-transition: @transition;
	-webkit-transition: ~"-webkit-@{transition}";
	-moz-transition: @transition;
	-ms-transition: @transition;
	-o-transition: @transition;
	transition: @transition;
}

.transition-spec (@attr, @duration, @easing) {
	-webkit-transition: @attr @duration @easing;
	-moz-transition: 	@attr @duration @easing;
	-o-transition:		@attr @duration @easing;
	-ms-transition: 	@attr @duration @easing;
	transition: 		@attr @duration @easing;
}


// .translate(VALUE, VALUE)

.translate(@x: 0, @y: 0) {
	-webkit-transform: translate(@x, @y);
	-moz-transform: translate(@x, @y);
	-ms-transform: translate(@x, @y);
	-o-transform: translate(@x, @y);
	transform: translate(@x, @y);
}

.translate3d(@x: 0, @y: 0, @z: 0) {
	-webkit-transform: translate3d(@x, @y, @z);
	-moz-transform: translate3d(@x, @y, @z);
	-ms-transform: translate3d(@x, @y, @z);
	-o-transform: translate3d(@x, @y, @z);
	transform: translate3d(@x, @y, @z);
}

// keyframes

.keyframes(@name, @frames) {
	@-webkit-keyframes @name { @frames(); }
	@-moz-keyframes @name { @frames(); }
	@-ms-keyframes @name { @frames(); }
	@-o-keyframes @name { @frames(); }
	@keyframes @name { @frames(); }
}

// .animation(arguments)

.animation(@arguments) {
	-webkit-animation:	@arguments;
	-moz-animation: 	@arguments;
	-ms-animation:		@arguments;
	animation:			@arguments;
}

// .background-alpha(VALUE VALUE);

.background-alpha(@color: @white, @alpha: 1) {
	background-color: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
}

// .background-size(VALUE VALUE);

.background-size(@size){
	-webkit-background-size: @size;
	-moz-background-size: @size;
	-o-background-size: @size;
	background-size: @size;
}

// .background-clip(VALUE); (border-box, padding-box, content-box)

.background-clip(@clip) {
	-webkit-background-clip: @clip;
	-moz-background-clip: @clip;
	background-clip: @clip;
}

// .box-sizing(VALUE); (border-box, padding-box, content-box)

.box-sizing(@boxsize: border-box) {
	-webkit-box-sizing: @boxsize;
	-moz-box-sizing: @boxsize;
	-ms-box-sizing: @boxsize;
	box-sizing: @boxsize;
}

// Chrome Frame

.chromeframe() { 
	margin: .2em 0;
	background: @lightgrey;
	color: @black;
	padding: .2em 0;
}

// Contain floats

.clearfix {
	*zoom: 1;

	&:before {
		content: " ";
		display: table;
	}

	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}

.clear(@hard:false) when (@hard = old) {
	&:before,
	&:after {
		display: table;
		content: '';
	}
	&:after {
		clear: both;
	}
	.lt-ie8 & {
		zoom: 1;
	}
}


// For image replacement

.ir() {
	overflow: hidden;
	text-indent: -999em;
}

// Hide from visual and speaking browsers

.hidden() {
	display: none !important;
	visibility: hidden;
}

// Hide text
.hide-text() {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

// Show text
.show-text() {
  text-indent: 0;
  white-space: normal;
  overflow: auto;
}

// Hide but maintain layout

.invisible() { 
	visibility: hidden;
}

// .resize(VALUE) (none, both, horizontal, vertical, inherit)

.resize(@direction: both) {
	resize: @direction;
	overflow: auto;
}

// .userselect(VALUE) (all, element, none, text)

.user-select(@select) {
	-webkit-user-select: @select;
	-moz-user-select: @select;
	-o-user-select: @select;
	user-select: @select;
}

.v-hidden() {
	overflow: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	&.focusable:active,
	&.focusable:focus {
		position: static;
		clip: auto;
		height: auto;
		width: auto;
		margin: 0;
		overflow: visible;
	}
}
