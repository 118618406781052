/*------------------------------------------------------------------
# [Slick plugin]
------------------------------------------------------------------*/

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
	//padding-bottom: 50px;
	z-index: 10;
}

.home-slider {
	&.slick-slider {
		z-index: 200;
	}
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&:before,
	&:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

.slick-prev,
.slick-next {
	outline: none;
	position: absolute;
	top: 50%;
	background-position: center center;
	background-repeat: no-repeat;
	background-color: transparent;
	border: none;
	height: 28px;
	width: 15px;
	background-size: 15px;
	.hide-text();
	z-index: 1;
	cursor: pointer;
	margin-top: -14px;
	.font-size(0);
}

.slick-prev {
	left: @spacer_mobile;
	background-image: url('../images/icon-prev.svg');
}

.slick-next {
	right: @spacer_mobile;
	background-image: url('../images/icon-next.svg');
}

.tabs-header {

	&.slider {

		.slick-prev,
		.slick-next {
			top: 5px;
			height: 20px;
			width: 12px;
			background-size: 12px;
			margin-top: 0;
		}

		.slick-prev {
			left: 0;
		}

		.slick-next {
			right: 0;
		}

		.slick-dots {
			bottom: 0px;
			left: 0px;

			li {
				float: none !important;
			}

			button {
				width: 4px;
				height: 4px;

				&:before {
					width: 2px;
					height: 2px;
				}
			}
		}
	}
}
