/*------------------------------------------------------------------
# [Footer]
------------------------------------------------------------------*/

.footer {
	.clearfix();
	clear: both;
	//border-top: 4px solid #f5f5f5;
	//padding: 30px 0 40px 0;

	.page-wrapper {
		text-align: center;
	}

	.footer-upper {
		background: #000;
		padding: 25px 0;
		margin-top: 23px;

		@media @bp_768 {
			padding: 70px 0;
		}

		span {
			display: block;
			text-align: center;
			color: @white-dark;
			font-size: 10px;
			font-weight: 300;
			line-height: 1.6;
			letter-spacing: 0.9px;
			text-transform: uppercase;

			@media @bp_768 {
				font-size: 13px;
			}

			a {
				text-decoration: none;
				color: @white-dark;
				text-transform: none;

				&:hover {
					color: @gold;
				}
			}
		}

		img {
			height: 12px;
			margin: 5px;
			vertical-align: bottom;
		}

		img.logo {
			display: block;
			width: 150px;
			height: auto;
			margin: 10px auto 25px auto;
			
			@media @bp_768 {
				width: 195px;
				margin: 10px auto 45px auto;
			}
		}
	}

	.footer-lower {
		padding: 23px;

		.locations {
			text-align: center;

			li {
				display: inline-block;
				padding: 0 20px;
			}

			a {
				color: @grey;
				text-decoration: none;
				text-transform: uppercase;
				.font-size(9);
				letter-spacing: 0.8px;

				@media @bp_768 {
					font-size: 13px;
					letter-spacing: 0.6px;
					font-weight: bold;
				}

				&:hover {
					color: @gold;
				}
			}
		}

		.footnote {
			.font-size(10);
			letter-spacing: 0.9px;
			color: #9b9b9b;
			text-align: center;
			padding: 23px 23px 0 23px;

			@media @bp_768 {
				.font-size(12);
			}
			
			span {
				display: block;

				@media @bp_768 {
					display: inline-block;
					padding: 0 10px;
				}
			}
			
			a {
				.font-size(8);
				font-size: 8px;
				letter-spacing: 0.7px;
				color: #9b9b9b;
				display: inline-block;
				margin: 0 10px;

				@media @bp_768 {
					.font-size(11);
				}

				&:hover {
					color: @gold;
				}
			}
		}
	}

	.col {
		vertical-align: top;
		width: 100%;
		display: block;

		@media @bp_768 {
			width: 44%;
			display: inline-block;
			max-width: 215px;
		}
	}
	
	.col-or {
		width: 8%;
		max-width: 64px;
		display: inline-block;
		vertical-align: top;

		span {
			margin: 20px 0;
			text-transform: none;
			color: @gold;

			@media @bp_768 {
				margin-top: 23px;
				font-size: 16px;
			}

		}
	}
}