/*------------------------------------------------------------------
# [Header]
------------------------------------------------------------------*/

.site-header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 99999;
  .transition-spec(all, 200ms, ease-in-out);
  background-color: @white;

  &.shadow {
    -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, .2);
    -moz-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, .2);
    box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, .2);
  }

  .nav {
    height: 73px;
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 99;

    @media @bp_768 {
      //height: 123px;
      height: 100px;
    }

    @media @bp_1400 {
      //left: 50%;
      //transform: translateX(-50%);
      //height: 150px;
      height: 100px;
      text-align: center;
      position: relative;
      margin: 0 auto;
      max-width: 1440px;
    }

    > .logo {
      .transition-spec(all, 200ms, linear);
      position: absolute;
      left: 70px;
      top: 23px;
      width: 111px;

      @media @bp_480 {
        left: 50%;
        margin-left: -55px;
      }

      @media @bp_768 {
        top: 18px;
        left: 50%;
        width: 205px;
        margin-left: -102px;
      }

      @media @bp_1400 {
        display: none;
      }
    }
  }

  .telephone {
    position: absolute;
    display: inline-block;
    top: 28px;
    right: 17px;
    .transition-spec(all, 200ms, linear);
    z-index: 1;

    @media @bp_768 {
      right: @spacer_tablet;
      top: 40px;
    }

    @media @bp_1400 {
      display: none;
      right: @spacer_desktop;
      top: 42px;
    }

    img {
      height: 9px;
      margin: 3px;
      vertical-align: middle;
      .transition-spec(all, 200ms, linear);

      @media @bp_768 {
        width: 14px;
        height: 14px;
        margin: 5px;
      }

      @media @bp_1400 {
        width: 18px;
        height: 18px;
        margin: 5px;
      }
    }

    a {
      color: @grey;
      .font-size(12);
      font-weight: 300;
      letter-spacing: 1.1px;
      text-decoration: none;

      @media @bp_768 {
        .font-size(14);
      }
    }
  }
}

.menu-trigger {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  left: 17px;
  top: 26px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  background: none;
  width: 27px;
  height: 22px;
  z-index: 1;
  .transition-spec(all, 200ms, linear);
  color: @blue;
  .font-size(17);

  @media @bp_768 {
    left: @spacer_tablet;
    height: 30px;
    width: 36px;
    top: 36px;
  }

  @media @bp_1400 {
    display: none;
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    top: 8px;
    right: 0px;
    width: 27px;
    height: 3px;
    background: @gold;

    @media @bp_768 {
      top: 12px;
      height: 6px;
      width: 36px;
    }

    &::before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: @gold;
      content: "";

      @media @bp_768 {
        height: 6px;
      }
    }

    &::before {
      top: -8px;

      @media @bp_768 {
        top: -12px;
      }
    }

    &::after {
      bottom: -8px;

      @media @bp_768 {
        bottom: -12px;
      }
    }

    &::before,
    &::after {
      transition-duration: 0.2s, 0.2s;
      transition-delay: 0.2s, 0s;
    }

    &::before {
      transition-property: top, transform;
    }

    &::after {
      transition-property: bottom, transform;
    }
  }

  &.open {

    .hide-text();

    /* active state, i.e. menu open */
    span {
      background: none;

      &::before {
        top: 0;
        .rotate(45deg);
      }

      &::after {
        bottom: 0;
        .rotate(-45deg);
      }

      &::before,
      &::after {
        width: 26px;
        height: 1px;
        top: 2px;
        background-color: @gold;
        transition-delay: 0s, 0.2s;

        @media @bp_768 {
          top: 4px;
          width: 36px;

        }
      }
    }
  }
}

.social {
  padding: 10px 0 10px @spacer_mobile;

  @media @bp_768 {
    padding-left: @spacer_tablet;
  }

  @media @bp_1400 {
    position: absolute;
    top: 13px;
    right: @spacer_desktop + 36px;
    padding: 0;
  }

  @media @bp_1400m {
    float: left;
  }

  .icon {
    background-size: 20px;
    display: block;
    .rounded-corners(100%);
    width: 20px;
    height: 20px;
    .hide-text();
    background: @black no-repeat center center;
    border: 1px solid @white;

    &:hover {
      border-color: @gold;
    }

    &.facebook {
      background-image: url('../images/icons/icon-facebook.svg');
      margin: 0 10px;
    }
  }

  li {
    float: left;

    .fa-instagram {
      color: #000;
      height: 20px;
      width: 20px;
      font-size: 20px;
    }
  }
}

.languages {
  padding: 10px 0 10px @spacer_mobile;
  z-index: 2;

  @media @bp_768 {
    padding-left: @spacer_tablet;
  }

  @media @bp_1400 {
    position: absolute;
    top: 13px;
    right: @spacer_desktop;
    padding: 0;
  }

  @media @bp_1400m {
    a, ul {
      float: left;
      padding-left: 10px;
    }

    li:first-child {
      padding-left: 10px;
    }
  }

  li {
    float: left;
    position: relative;

    @media @bp_1400 {
      padding: 0 5px 5px 5px;
    }

    &:first-child {
      margin-left: 0;
    }

    > ul {
      .clearfix;

      @media @bp_1400 {
        display: none;
        position: absolute;
        top: 25px;
        left: 0px;
        background-color: #f3f0ea;
        .rounded-corners(3px);
      }

      li {
        padding-left: 10px;

        @media @bp_1400 {
          padding: 5px;
        }

        &:first-child {
          padding-left: 0px;

          @media @bp_1400 {
            padding: 5px;
          }
        }
      }
    }

    &:hover {

      @media @bp_1400 {

        > ul {
          display: block;
        }
      }
    }

    .current {
      display: none;

      @media @bp_1400 {
        display: block;
      }
    }
  }

  .lang {
    background: no-repeat center center;
    background-size: 20px;
    display: block;
    .rounded-corners(100%);
    width: 20px;
    height: 20px;
    .hide-text();
    border: 1px solid @white;

    &:hover {
      border-color: @gold;
    }

    &.sl {
      background-image: url('../images/icons/icon-flag-sl.png');
    }

    &.de {
      background-image: url('../images/icons/icon-flag-de.png');
    }

    &.uk {
      background-image: url('../images/icons/icon-flag-uk.png');
    }

    &.ru {
      background-image: url('../images/icons/icon-flag-ru.png');
    }

    &.it {
      background-image: url('../images/icons/icon-flag-it.png');
    }
  }
}

.nav-wrapper {
  position: absolute;
  top: 73px;
  background-color: #f3f0eb;
  width: 100%;
  overflow: hidden;
  z-index: 0;
  height: 0px;
  max-height: 100vh;
  .transition-spec(height, 500ms, ease-in-out);
  z-index: 0;

  &.open {
    height: 100vh;

    @media @bp_1400 {
      height: auto;
    }
  }

  @media @bp_768 {
    top: 100px;
  }

  @media @bp_1400 {
    position: relative;
    display: inline-block;
    max-height: none;
    height: auto;
    top: 0;
    background-color: transparent;
    overflow: visible;
    max-width: 1440px;
  }

  .telephone {
    display: none;

    @media @bp_1400 {
      display: block;
    }
  }
}

.menu {

  > ul {
    padding: 10px @spacer_mobile;

    @media @bp_768 {
      padding: 30px @spacer_tablet;
    }

    @media @bp_1400 {
      padding: 15px 0 0 0;
      text-align: center;
      margin: 0 auto;
    }

    > li {
      list-style: none;
      padding-top: 25px;

      @media @bp_768 {
        padding-top: 35px;
      }

      @media @bp_1400 {
        padding: 0;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
      }

      > a {
        position: relative;
        color: @grey;
        text-decoration: none;
        .font-size(18);
        font-weight: normal;
        letter-spacing: 4.1px;
        text-transform: uppercase;
        z-index: 1;

        @media @bp_1400 {
          .font-size(13);
          font-weight: normal;
          letter-spacing: 1.6px;
          line-height: 75px;
          //line-height: 14px;
          font-weight: bold;
          padding: 0 10px;
          height: 100%;
          display: block;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 10px;
            right: 10px;
            height: 50px;
            transform: scaleX(0);
            transform-origin: top center;
            border-bottom: 1px solid @gold;
            z-index: -1;
            transition: transform 0.4s;
          }

          &:hover::before {
            transform: scaleX(1);
          }
        }

        @media @bp_1400 {
          padding: 0 20px;

          &:before {
            left: 20px;
            right: 20px;
          }
        }
      }

      @media @bp_1400 {

        &.has-submenu {

          &:hover {
            background-color: @white-dark;
          }

          > a {

            &:before {
              content: none;
            }

            &.active {
              background: @white-dark;
            }

            &:hover {

              & + .submenu {
                transition-delay: 0s;
                max-height: 100vh;
              }
            }
          }
        }
      }
    }

    > li.logo {
      display: none;

      @media @bp_1400 {
        display: inline-block;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
      }

      > a {
        line-height: 0;

        &:hover {
          background: #fff !important;
        }

        &:before,
        &:after {
          content: none;
        }
      }

      img {
        height: 65px;
        float: left;
      }
    }
  }
}

.submenu {
  overflow: hidden;
  max-height: 0;
  position: absolute;
  top: 90px;
  left: 0;
  width: 100%;
  background: @white-dark;
  text-align: left;
  transition: 0.4s ease-in-out;
  z-index: 2;

  &:hover {
    max-height: 100vh;
  }

  > li {
    display: inline-block;
    position: relative;
    list-style: none;
    float: left;
    width: 24%;

    > a {
      padding: 25px 20px 0 20px;
      color: @gold;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.52;
      letter-spacing: 2.1px;
      margin-bottom: 20px;
      display: block;

      @media @bp_1400 {
        padding: 25px 45px 0 45px;
      }
    }

    > ul {
      padding: 0 20px;
      list-style: none;

      @media @bp_1400 {
        padding: 0 45px;
      }

      > li {

        > a {
          font-size: 13px;
          font-weight: 300;
          line-height: 1.15;
          letter-spacing: 0.8px;
          margin-bottom: 15px;
          display: block;
          text-decoration: none;
          color: @grey;

          &:hover {
            text-decoration: underline;
          }
        }

        &.more {

          > a {
            margin-top: 20px;
            color: @gold;
          }
        }
      }
    }
  }

  .menu-additionals {
    display: block;
    width: 100%;
    background: @white-darker;
    position: relative;

    ul.hotlinks {
      padding: 5px;

      li {
        display: inline-block;

        img {
          height: 40px;
          vertical-align: middle;
        }

        a {
          background: #fff;
          padding: 10px;
          color: @gold;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 1.52;
          letter-spacing: 2.1px;
          margin-bottom: 0;

          &:hover {
            text-decoration: none;
          }
        }

        span {
          display: inline-block;
          max-width: 85px;
          margin: 0 15px;
          vertical-align: middle;
        }
      }
    }

    .more {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}