/*------------------------------------------------------------------
# [Banners]
------------------------------------------------------------------*/

.cc-banner {

    background-color: @black;
    color: @white;
    .font-size(10);
    text-align: center;

    @media @bp_768 {
      .font-size(12);
    }

    a {
      color: @white;

    }

    br {

      @media @bp_768 {
        display: none;
      }
    }

}