/*------------------------------------------------------------------
# [Global]
------------------------------------------------------------------*/

.hide-mobile{
	display: none;

	@media @bp_768 {
		display: block;
	}
}

.mobile-only {

	@media @bp_768 {
		display: none;
	}
}

ul,
li {
	margin: 0;
	padding: 0;
	list-style: none;
}

a {
	text-decoration: none;

	&:hover {
		text-decoration: none;
	}
}

img {
	max-width: 100%;
}

.center {
	text-align: center;
}

.embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	background-color: @white-dark;
	margin: 30px 0;
	border: 25px solid @white-dark;

	@media @bp_768 {
		border: 45px solid @white-dark;
	}

	img {
		max-width: 100%;
	}

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.expand-toggle {
	cursor: pointer;

	&:before {
		content: "";
		margin-right: 15px;
		display: inline-block;
		width: 12px;
		height: 12px;
		background-image: url('../images/icon-plus.svg');
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 12px;

		@media @bp_1024 {
			display: none;
		}
	}

	&.open {
			
		&:before {
			background-image: url('../images/icon-minus.svg');

			@media @bp_1024 {
				display: none;
			}
		}
	}
}

.expand-content {
	display: none;
	overflow: hidden;
	margin: 0;
	padding: 0;
}