#menu {
  width: 190px;
  top: 40%;
  z-index: 20;
  position: fixed;
  right: 0;

  @media @desktop {
    margin-left: -135px;
  }

  @media @phablets {
    right: -150px;

    li:hover {
      margin-left: -135px;
      transition: 0.25s;
    }
  }

  li {
    background-color: @gold;
    transition: 0.25s;

    a {
      color: white;
      display: block;
      padding: 8px 0;
      margin: 6px 0;
    }

    .fa {
      color: #ffffff;
      padding-right: 10px;
      padding-left: 10px;
      font-size: 2.4rem;
      vertical-align: middle;
    }

    .fa-calendar-check-o {
      font-size: 2.2rem;
      padding-right: 8px;
    }
  }
}
*>#menu {
  position: fixed;
}