/*------------------------------------------------------------------
# [Variables]
------------------------------------------------------------------*/

@body-bg-color: #fff;
@text-color: #585858;

@base-font: 'Roboto', sans-serif;
@base-font-size: 16;
@base-line-height: 1.5em;
@base-heading-font: Georgia;

@text-font: 'Roboto', sans-serif;

@base-color: rgb(35,31,32);
@base-color-light: rgb(90,90,90);
@comp-color: spin(@base-color, 180);
@border-color: lighten(@base-color, 60%);

// links

@link-color: rgb(1,53,104);
@link-color-hover: darken(@link-color, 10);
@link-color-visited: darken(@link-color-hover, 10);
@link-color-focus: darken(@link-color-visited, 10);

// buttons

@btn-border: #e5ded3;

// colors

@black: #000;
@white: #fff;
@white-dark: #eae3d9;
@white-darker: #eae4d9;
@gold: #a7916d;
@grey: #4a4a4a;
@grey-light: #f5f5f5;
@blue: #0069b5;
@green: #d1f8a5;
@red: #fc9eaa;
// forms

@input-background: #f5f5f5;
@input-text-color: @text-color;
@input-border: #f5f5f5;
@input-border-width: 1px;
@input-border-style: solid;
@input-focus: @base-color, 40%;
@placeholder-color: @grey;

// layout

@spacer_mobile: 30px;
@spacer_mobile_landscape: 30px;
@spacer_tablet: 90px;
@spacer_desktop: 17px;

@max-page-width: 1440px;
@max-page-mq-width: 1380px;

@wyz-calendar-today: @gold;
@wyz-calendar-available: @white;
@wyz-calendar-selected: @gold;

// media queries

@phablets: ~ "only screen and (max-width: 1199px)";
@desktop: ~ "only screen and (min-width: 1200px)";
@min400: ~ "only screen and (min-width: 400px)";
@min550: ~ "only screen and (min-width: 550px)";
@min750: ~ "only screen and (min-width: 750px)";
@min1000: ~ "only screen and (min-width: 1000px)";