/*------------------------------------------------------------------
# [Animations]
------------------------------------------------------------------*/

.keyframes(top-to-bottom;{
	0% { background-position: center 0px; }
	100% { background-position: center 111px; }
});

.keyframes(left-to-right;{
	0% { background-position: 0px center; }
	100% { background-position: 151px center; }
});